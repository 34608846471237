import { contacts } from "../data/contacts";
import { ComingSoon } from "./ComingSoon";

export const Announce = () => {
  return (
    <div className="announce">
      <p>New store opening soon at</p>
      <p>{contacts.address}</p>
      <img
        loading="lazy"
        src="../images/main_entrance_final.jpg"
        alt="Multicook Brandon"
        className="announce-image"
      />
      <ComingSoon />
    </div>
  );
};
