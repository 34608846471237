export const Devider = () => {
  return (
    <div className="devider-container">
      <div className="devider-side">
        <div className="devider-line"></div>
        <div></div>
      </div>
      <div className="devider-center">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="48"
          height="48"
          viewBox="0 0 24 24"
        >
          <g
            fill="none"
            stroke="#c41d18"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="4"
          >
            <circle cx="12" cy="12" r="10" />
            <path d="M12 6v6" />
          </g>
        </svg>
      </div>
      <div className="devider-side">
        <div className="devider-line"></div>
        <div></div>
      </div>
    </div>
  );
};
