import "./App.css";
import { Announce } from "./components/Announce";
import { Contacts } from "./components/Contacts";
import { Delivery } from "./components/Delivery";
import { Devider } from "./components/Devider";
import { Footer } from "./components/Footer";
import { Hero } from "./components/Hero";
import { MenuSection } from "./components/MenuSection";
import { SiteCredits } from "./components/SiteCredits";
import { products } from "./data/products";

function App() {
  return (
    <div className="App">
      <Contacts />
      <Hero />
      <Announce />
      <Devider />
      <Delivery />

      <Devider />
      {products.map((el) => (
        <MenuSection data={el} key={el.categoryId} />
      ))}
      <Devider />
      <Footer />
      <SiteCredits />
    </div>
  );
}

export default App;
