import { useRef } from "react";
import Carousel from "react-multi-carousel";
import useFirstViewportEntry from "../hooks/useFirstViewportEntry";
import { viewportData } from "../data/constants";
import "react-multi-carousel/lib/styles.css";
import { cutName } from "../data/helpers";
import { Icon } from "@iconify/react/dist/iconify.js";

export const MenuSection = ({ data }) => {
  const ref = useRef(null);
  const isVisible = useFirstViewportEntry(ref);

  return (
    <div className={`menu-section ${isVisible > 0 && "visible"}`} ref={ref}>
      <h3 className="section-header">{data.categoryName}</h3>
      <Carousel responsive={viewportData}>
        {data.products.map((el) => (
          <div key={el.productId} className="product">
            <div className="image-wraper">
              <img
                className="menu-image"
                loading="lazy"
                src={
                  el.productImg
                    ? `../images/${el.productImg}.webp`
                    : "../images/mock.jpg"
                }
                onError={({ currentTarget }) => {
                  currentTarget.onerror = null;
                  currentTarget.src = "../images/mock.jpg";
                }}
                alt={el.productName}
              />
            </div>
            <h6 className="product-name">{cutName(el.productName, "VEGA")}</h6>
            <div className="ingredients-wraper">
              <p className="product-ingredients">{el.ingredients}</p>
            </div>
            {el.vega && <Icon icon="mdi:leaf" className="vegan-icon" />}
          </div>
        ))}
      </Carousel>
    </div>
  );
};
