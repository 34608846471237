export const products = [
  //   {
  //     categoryName: "",
  //     categoryId: "",
  //     products: [
  //       { productId: "", productName: "", ingredients: "", productImg: "" },
  //     ],
  //   },
  {
    categoryName: "Perogies",
    categoryId: "d08cf2e6-ffd3-45d0-9c9c-4c50ea13e230",
    products: [
      {
        productId: "61d1f85e-b67c-4cf9-8ce8-41acff725317",
        productName: "Cabbage and  wild mushroom perogies VEGA",
        ingredients:
          "wheat flour, sauerkraut,water, mushroom, fried onions, fried carrot, dried ground wild mushrooms, refined sunflower oil, salt, ground black pepper.",
        productImg: "DSC_0488_2",
        vega: true,
      },
      {
        productId: "7ad408f2-3571-4152-9347-4831d92dff09",
        productName: "Spinach and cottage cheese perogies ",
        ingredients:
          "wheat flour, cottage cheese, water, spinach, eggs, fresh dill and persley, refined sunflower oil, salt.",
        productImg: "DSC_8033",
        vega: false,
      },
      {
        productId: "2b6d9bde-85a9-4078-b93f-9c31268a2570",
        productName: "Cottage cheese perogies",
        ingredients:
          "wheat flour, cottage cheese, water, eggs, fresh dill and parsley, refined sunflower oil, salt. ",
        productImg: "DSC_5656",
        vega: false,
      },
      {
        productId: "8429bcb0-57eb-45c5-aacb-8b009099f6f2",
        productName: "Sweet cottage cheese perogies",
        ingredients:
          "wheat flour, cottage cheese, water, white sugar, eggs, vanilla sugar, refined sunflower oil, and salt.",
        productImg: "DSC_8100",
        vega: false,
      },
      {
        productId: "243e3fd1-ce61-4ec1-93b3-d95b67a47d55",
        productName: "Sour cherry perogies VEGA",
        ingredients:
          "wheat flour, frozen sour cherries, water, white sugar, refined sunflower oil, and salt. *may contain pits",
        productImg: "DSC_8054",
        vega: true,
      },
      {
        productId: "cbb8992f-809a-45cb-bfa5-cf278974e926",
        productName: "Blueberry perogies VEGA",
        ingredients:
          "wheat flour, frozen blueberry, water, white sugar, refined sunflower oil, salt.",
        productImg: "DSC_6229",
        vega: true,
      },
      {
        productId: "2531969e-1c37-41a8-9422-6e1c719f059e",
        productName: "Meat perogies",
        ingredients:
          "wheat flour, lean ground pork, pork lard, water, fried onions, salt, ground black pepper. ",
        productImg: "perigies_meat",
        vega: false,
      },
      {
        productId: "c9b97297-cf7a-44f6-87a1-86eaccfb53b1",
        productName: "Liver perogies ",
        ingredients:
          "wheat flour, liver (pork heart, lungs, fat), water, fried onions, refined sunflower oil, salt, ground black pepper. ",
        productImg: "liver_perogies",
        vega: false,
      },
      {
        productId: "9ab16702-0fc4-4ea5-afb1-b4881133aa91",
        productName: "Potato perogies",
        ingredients:
          "wheat flour, potatoes, water, fried onions, refined sunflower oil, salt, ground black pepper. ",
        productImg: "potato_perogies",
        vega: true,
      },
      {
        productId: "5adaed06-256d-4464-b8e5-1408a7f951aa",
        productName: "Potato and bacon perogies ",
        ingredients:
          "wheat flour, potatoes, water, bacon, fried onions, refined sunflower oil, salt, ground black pepper.",
        productImg: "perogies_bacon",
        vega: false,
      },
      {
        productId: "29a10fae-47e3-463f-97eb-afa4b696d02f",
        productName: "Potato and butter perogies",
        ingredients:
          "wheat flour, potatoes, water, butter, refined sunflower oil, salt, ground black pepper.",
        productImg: "perogies_with_butter",
        vega: false,
      },
      {
        productId: "cddde6a1-025e-45c5-85a2-9e5a6c71744e",
        productName: "Potato and cottage cheese ",
        ingredients:
          "wheat flour, potatoes, water, cottage cheese, refined sunflower oil, salt, ground black pepper.",
        productImg: "perogies_poatto_cottage_cheese",
        vega: false,
      },
      {
        productId: "a76dd4d1-f480-46b7-8885-c860020efcab",
        productName: "Potato and mushroom perogies",
        ingredients:
          "wheat flour, potatoes, water, mushroom, fried onions, refined sunflower oil, salt, ground black pepper.",
        productImg: "perigies_with_mushroom",
        vega: true,
      },
      {
        productId: "24a3b58d-5c4d-4674-adf2-7d2be88214ad",
        productName: "Potato and wild mushroom perogies",
        ingredients:
          "wheat flour, potatoes, water, mushroom, fried onions, dried ground wild mushrooms, refined sunflower oil, salt, ground black pepper.",
        productImg: "perogies_with_mushroom1",
        vega: true,
      },
      {
        productId: "6287ea65-b07c-4f2d-9fef-17f11a803aec",
        productName: "Cabbage perogies",
        ingredients:
          "wheat flour, sauerkraut, water, fried carrots, fried onions, refined sunflower oil, salt, ground black pepper.",
        productImg: "cabbage_perogies",
        vega: true,
      },
    ],
  },
  {
    categoryName: "Dumplings (pelmeni)",
    categoryId: "95e6e0db-2fae-489a-998e-45094ca77db5",
    products: [
      {
        productId: "d36006e0-f738-4c51-baba-a7e94ba9f285",
        productName: "Pork dumplings (pelmeni)",
        ingredients:
          "wheat flour, lean ground pork, pork lard, water, onions, refined sunflower oil, salt, ground black pepper.  ",
        productImg: "pelmeni",
        vega: false,
      },
      {
        productId: "7655040d-c095-4c27-bc0b-56a9be32442c",
        productName: "Pork and beef dumplings (pelmeni)",
        ingredients:
          "wheat flour, lean ground pork, lean ground beef, pork lard, water, onions, refined sunflower oil, salt, ground black pepper.  ",
        productImg: "Just_pelmeni2",
        vega: false,
      },
      {
        productId: "25b2392b-54fb-480e-bf77-8bc9ec97225b",
        productName: "Veal dumplimgs (pelmeni)",
        ingredients:
          "wheat flour, lean ground veal, water, onions, refined sunflower oil, salt, ground black pepper.  ",
        productImg: "pelmeni_veal",
        vega: false,
      },
      {
        productId: "a56c88df-bc2d-44a6-99ad-5595d43c8ea3",
        productName: "Chicken and pork dumplings (pelmeni)",
        ingredients:
          "wheat flour, chicken breast, lean ground pork, pork lard, water, onions, refined sunflower oil, salt, ground black pepper.  ",
        productImg: "P1510083",
        vega: false,
      },
      {
        productId: "3f299024-7d20-4648-bf42-35697e41c686",
        productName: "Corn-flour chicken dumplings (pelmeni)",
        ingredients:
          "wheat flour, lean ground chicken, pork lard, water, corn flour, onions, refined sunflower oil, salt, ground black pepper.  ",
        productImg: "2",
        vega: false,
      },
      {
        productId: "acb1bde9-8467-45fd-bbb9-7ef792687da6",
        productName: "Kid's chicken dumplings (pelmeni)",
        ingredients:
          "wheat flour, chicken breast, beet juice, carrot juice, spinach juice, drinking water, onions, refined sunflower oil, salt.",
        productImg: "children_pelmeni",
        vega: false,
      },
      {
        productId: "9fc531c2-b810-4a71-8c00-83dd8e698d27",
        productName: "Turkey dumplings (pelmeni)",
        ingredients:
          "wheat flour, ground turkey, water, onions, refined sunflower oil, salt, ground black pepper.",
        productImg: "P1500687",
        vega: false,
      },
      {
        productId: "35901a40-d5db-4a27-beec-018f20e7d70b",
        productName: "Three Meats dumplings (pelmeni)",
        ingredients:
          "wheat flour, ground chicken thighs, lean ground pork, lean ground beef, pork lard, water, onions, refined sunflower oil, fresh dill, salt, ground black pepper.  ",
        productImg: "DSC_6336",
        vega: false,
      },
      {
        productId: "11ea7870-7322-4c54-8fb1-6f3d5496aded",
        productName: "Salmon and cottage cheese dumplings (pelmeni)",
        ingredients:
          "wheat flour, salmon fillet, cottage cheese, water, refined sunflower oil, fresh dill, salt, ground black pepper. ",
        productImg: "sch3",
        vega: false,
      },
      {
        productId: "7800bee5-83d0-4c44-aeca-390ac7493282",
        productName: "Salmon and spinach dumplings (pelmeni)",
        ingredients:
          "wheat flour, salmon fillet, spinach, water, refined sunflower oil, salt, ground black pepper. ",
        productImg: "ssh1",
        vega: false,
      },
      {
        productId: "ed94d77c-190a-4266-9d46-500ae6ef4b10",
        productName: "Fish dumplings (pelmeni) (lent dish)",
        ingredients:
          "wheat flour, fish fillet, water, onions, salt, ground black pepper. ",
        productImg: "Fish_dumplings",
        vega: false,
      },
      {
        productId: "a807422f-4866-4e94-90ed-889da70ad8b6",
        productName: "Pork khinkali",
        ingredients:
          "wheat flour, dlean ground pork, pork lard, water, onions, fresh herbs, potato startch, suneli spice, salt, ground black and red pepper.  ",
        productImg: "DSC_8076",
        vega: false,
      },
    ],
  },
  {
    categoryName: "Pancakes & Crepes",
    categoryId: "efabc32b-15b8-4238-af2c-21da64255b29",
    products: [
      {
        productId: "bc7afde6-8c31-4757-b551-07402af4684c",
        productName: "Syrnyky (cottage cheese pancakes) ",
        ingredients:
          "cottage cheese, wheat flour, white sugar, eggs (yolks), vanilla sugar. ",
        productImg: "DSC_6376",
        vega: false,
      },
      {
        productId: "afe0d65a-511c-43fa-8cee-9fba1e38eaeb",
        productName: "Syrnyky (cottage cheese pancakes) with raisins",
        ingredients:
          "cottage cheese, raisins, wheat flour, white sugar, eggs (yolks), salt.",
        productImg: "DSC_7623",
        vega: false,
      },
      {
        productId: "569e437f-dfb2-4434-bc37-ddcc05fb0745",
        productName: "Meat Crepes",
        ingredients:
          "milk, lean ground pork, wheat flour, pork lard, fried onions, butter, eggs, water, salt, white sugar, and ground black pepper. ",
        productImg: "DSC_7692",
        vega: false,
      },
      {
        productId: "7d71b06e-01c0-4b8a-925b-f86e9aa5bd55",
        productName: "Chichen and mushroom crepes",
        ingredients:
          "milk, wheat flour, chicken breast, fried mushrooms, fried onions, butter, eggs, water, salt, white sugar, and ground black pepper. ",
        productImg: "DSC_7725",
        vega: false,
      },
      {
        productId: "348c8252-7b94-4f59-8e03-c11ef9d870a3",
        productName: "Liver crepes",
        ingredients:
          "milk, liver (pork heart, lungs, fat), wheat flour, pork lard, fried onions, butter, eggs, water, refined sunflower oil, salt, white sugar, ground black pepper.  ",
        productImg: "DSC_7677",
        vega: false,
      },
      {
        productId: "97b3a171-e085-43fd-9160-322b3406a164",
        productName: "Ham and Cheddar cheese crepes",
        ingredients:
          "milk, ham, wheat flour, cheddar cheese, mayonnaise sauce, whipping cream, eggs, butter, water, salt, white sugar, mustard sauce, apple vinegar, fresh dill.",
        productImg: "ham_and_cheese",
        vega: false,
      },
      {
        productId: "672d4c8b-5401-49eb-90a5-77f98fb1a5ab",
        productName: "Salmon and spinach crepes",
        ingredients:
          "milk, salmon fillets, wheat flour, spinach, butter, chicken eggs, water, salt, white sugar.",
        productImg: "DSC_5453",
        vega: false,
      },
      {
        productId: "5366558e-631d-4355-8689-eb7e9f26be17",
        productName: "Salmon and cottage cheese creepes",
        ingredients:
          "milk, salmon fillets, wheat flour, cottage cheese, butter, eggs, water, fresh dill, salt, and white sugar.",
        productImg: "salmon_cheesw_crepes",
        vega: false,
      },
      {
        productId: "60164de0-7860-4a2f-b028-84755e6e3200",
        productName: "Bryndza cheese and fresh herbs crepes",
        ingredients:
          'milk, "bryndza" cheese, wheat flour, butter, eggs, drinking water, fresh dill and parsley, ground dried garlic, salt, white sugar, and ground black pepper.',
        productImg: "DSC_7708",
        vega: false,
      },
      {
        productId: "c0553ed7-8d20-4f28-8f05-912fedc2b718",
        productName: "Spinach and cream cheese crepes",
        ingredients:
          "milk, cream cheese, wheat flour, spinach, butter, eggs, water, salt, white sugar.",
        productImg: "spinach_and_cheese_crepes",
        vega: false,
      },
      {
        productId: "3c1cc3c4-6c59-49ae-a75b-69f48ae6e933",
        productName: "Benderiki",
        ingredients:
          "milk, lean ground pork, wheat flour, pork lard, onions, water, butter, eggs, salt, white sugar, and ground black pepper. ",
        productImg: "DSC_6702",
        vega: false,
      },
      {
        productId: "99faf933-d23f-4696-bc7b-3e0fbee4c191",
        productName: "Sweet cottage cheese crepes",
        ingredients:
          "milk, cottage cheese, wheat flour, white sugar, butter, eggs, water, salt, vanilla sugar.",
        productImg: "sweet_cheese_crepes",
        vega: false,
      },
      {
        productId: "76509377-3ddd-43c2-b110-2c49a1a9bc5b",
        productName: "Cottage cheese and blueberry crepes",
        ingredients:
          "milk, cottage cheese, wheat flour, blueberry, white sugar, butter, eggs, drinking water, salt, vanilla sugar.",
        productImg: "DSC_7563",
        vega: false,
      },
      {
        productId: "934372b0-4a06-4676-9cab-f64a184fe49e",
        productName: "Cottage cheese and strawberry crepes",
        ingredients:
          "milk, cottage cheese, wheat flour, strawberry, white sugar, butter, eggs, water, salt, and vanilla sugar.",
        productImg: "DSC_7535",
        vega: false,
      },
      {
        productId: "8c9ed949-1e73-4c61-a442-fc67d2e8e32a",
        productName: "Poppy seeds and sour cherry crepes",
        ingredients:
          "milk, poppy seeds, wheat flour, sour cherry, white sugar, butter, eggs, drinking water, and salt. *may contain pits",
        productImg: "DSC_7854",
        vega: false,
      },
      {
        productId: "5e32d07c-b37b-4e1d-9fdf-e11c4c94b90c",
        productName: "Caramelized apple crepes",
        ingredients:
          "milk, apple, wheat flour, white sugar, butter, eggs, water, salt, and cinnamon.",
        productImg: "DSC_7634",
        vega: false,
      },
      {
        productId: "963bcbbc-4f30-4385-a38e-d294104c53a2",
        productName: "Banana and condensed milk crepes",
        ingredients:
          "milk, banana, wheat flour, condensed milk, white sugar, butter, eggs, drinking water, and salt.",
        productImg: "DSC_7839",
        vega: false,
      },
    ],
  },
  {
    categoryName: "Chebureki",
    categoryId: "aec9cef2-6162-4415-995f-f51df7825c6c",
    products: [
      {
        productId: "260c090c-e16d-4a02-b39e-197216664f9a",
        productName: "Pork chebureki",
        ingredients:
          "wheat flour, lean ground pork, water, onions, pork lard, refined sunflower oil, vinegar, white sugar, salt, and ground black pepper.  ",
        productImg: "DSC_7743",
        vega: false,
      },
      {
        productId: "efe4828a-ec1a-45be-9998-769a89a11f53",
        productName: "Pork, cheddar cheese and tomato chebureki",
        ingredients:
          "wheat flour, water, lean ground pork, cheddar cheese, tomato, onions, pork lard, refined sunflower oil, vinegar, white sugar, salt, ground black pepper.  ",
        productImg: "cheddar_tomato",
        vega: false,
      },
      {
        productId: "03e6bf8d-bcf2-46ea-9d47-f64b7da9ffdd",
        productName: "Fish chebureki",
        ingredients:
          "wheat flour, fish fillets, water, onion, pork lard, refined sunflower oil, vinegar, white sugar, salt, and ground black pepper.  ",
        productImg: "Fish_chebureki",
        vega: false,
      },
      {
        productId: "5f7119c3-4557-45f8-9f47-0faeb52bbd27",
        productName: "Cottage/cheddar cheese and fresh herbs chebureki",
        ingredients:
          "wheat flour, cottage cheese, cheddar cheese, water, refined sunflower oil, fresh dill and parsley, vinegar, white sugar, salt, and ground black pepper.  ",
        productImg: "cheese_herbs_chebureki",
        vega: false,
      },
      {
        productId: "b282c233-9268-4347-9455-fb5b02576759",
        productName: "Cabbage chebureki",
        ingredients:
          "wheat flour, sauerkraut, water, onions, refined sunflower oil, vinegar, salt, and ground black pepper.",
        productImg: "cabbage_chebureki",
        vega: true,
      },
    ],
  },
  {
    categoryName: "Hashbrowns & Zrazes",
    categoryId: "0bed5d0b-83d6-42da-8e2f-eb450d377ba7",
    products: [
      {
        productId: "a65ba49a-0fde-43e7-99d0-02fe8776d9a9",
        productName: "Potato hashbrowns",
        ingredients:
          "potatoes, onion, wheat flour, eggs, mayonnaise, salt, and ground black pepper. *may contain mustard",
        productImg: "potato_hashbrowns",
        vega: false,
      },
      {
        productId: "4074125b-7ea0-417a-8a18-70dc6c1e0f2a",
        productName: "Meat popato hashbrowns ",
        ingredients:
          "potatoes, lean ground pork, onion, pork lard, wheat flour, eggs, mayonnaise, salt, and ground black pepper. *may contain mustard",
        productImg: "meat_potato_hashbrowns",
        vega: false,
      },
      {
        productId: "a888a85c-92b9-47f9-855b-09cc6305572d",
        productName: "Mushroom potato hashbrowns",
        ingredients:
          "potatoes, fried mushroom, onion, wheat flour, eggs, mayonnaise, salt, and ground black pepper. *may contain mustard",
        productImg: "mushroom_hashbrowns",
        vega: false,
      },
      {
        productId: "775c1747-2059-4798-8a7d-aa13d09fe65f",
        productName: "Meat zrazes",
        ingredients:
          "potatoes, lean ground pork, premium wheat flour, pork lard, fried onion,  eggs, refined sunflower oil, salt, and ground black pepper. ",
        productImg: "DSC_6477",
        vega: false,
      },
      {
        productId: "8122e679-f46d-4fde-bb6a-15a69e50491a",
        productName: "Liver zrazes",
        ingredients:
          "potatoes, liver (pork heart, lungs, fat), wheat flour, fried onion,  eggs, refined sunflower oil, salt, and ground black pepper.  ",
        productImg: "DSC_6504",
        vega: false,
      },
      {
        productId: "c6195d72-8d3d-446e-a85c-11c315f225a7",
        productName: "Cabbage zrazes",
        ingredients:
          "potatoes, sauerkraut, wheat flour, eggs, fried onion, refined sunflower oil, salt, and ground black pepper.  ",
        productImg: "DSC_6401",
        vega: false,
      },
      {
        productId: "4d8521ea-30d7-44b9-9b4e-266c4b1704f0",
        productName: "Mushroom zrazes",
        ingredients:
          "potatoes, fried mushroom, wheat flour, fried onion, eggs, refined sunflower oil, salt, and ground black pepper.   ",
        productImg: "DSC_6420",
        vega: false,
      },
    ],
  },
  {
    categoryName: "Sausage",
    categoryId: "07348748-0c02-4d45-ba45-3095abfacb53",
    products: [
      {
        productId: "ed38a1dd-13fc-44e9-ac32-bc51d4055f1d",
        productName: "Home made sausage",
        ingredients:
          "lean pork, pork lard, water, ground dried garlic, mustard seed, sausage casing, salt, and ground black pepper. ",
        productImg: "sausage",
        vega: false,
      },
      {
        productId: "652e6c6e-fa4c-45dd-ad73-defd7ac7b1fa",
        productName: "Grilled pork sausage",
        ingredients:
          "lean pork, pork lard, water, ground dried garlic, sausage casing, salt, and ground black pepper. ",
        productImg: "DSC_6669",
        vega: false,
      },
      {
        productId: "9d31205b-a02e-4d5f-912a-04eae5ffad37",
        productName: "Grilled chichen and cheddar cheese sausage",
        ingredients:
          "chicken fillets, pork lard, cheddar cheese, water, ground dried garlic, sausage casing, salt, and ground black pepper. ",
        productImg: "chicken_cheese_sausage",
        vega: false,
      },
    ],
  },
  {
    categoryName: "Cutlets & meatballs",
    categoryId: "34768e06-0631-46b7-944f-d23defe95770",
    products: [
      {
        productId: "6f184f47-a821-4cc6-8883-7f22f6b3425f",
        productName: "Pork cutlets",
        ingredients:
          "lean ground pork, pork lard, white bread, water, bread crumbs, eggs, onions, salt, and ground black pepper.\n",
        productImg: "DSC_6538",
        vega: false,
      },
      {
        productId: "7fbe72d4-bd01-4c19-9584-ac089baa4c7f",
        productName: "Pork and beef cutlets",
        ingredients:
          "lean ground pork, lean ground beef, pork lard, onion, white bread, water, bread crumbs, eggs, salt, and ground black pepper.",
        productImg: "pork_beef_cutlets",
        vega: false,
      },
      {
        productId: "43b66237-1578-43cb-aeb0-3ecb5cff32f2",
        productName: "Chichen cutlets",
        ingredients:
          "chicken breast, lean ground pork, pork lard, white bread, water, onion, bread crumbs, eggs, salt, ground black pepper.",
        productImg: "chicken_cutlets",
        vega: false,
      },
      {
        productId: "c1393f15-e990-4b23-9998-ba2cb6c4ef21",
        productName: "Cutlet «Cordon bleu» ",
        ingredients:
          "chicken breast, pork lard, cheddar cheese, butter, white bread, bread crumbs, eggs, fresh dill and parsley, salt, and ground black pepper.",
        productImg: "DSC_6603",
        vega: false,
      },
      {
        productId: "221974a6-546f-40c0-b4b5-8401118919fb",
        productName: "Cutlet «Turkey»",
        ingredients:
          "turkey, white bread, water, onion, corn crumbs, eggs, salt, and ground black pepper.",
        productImg: "turkey_cutlets",
        vega: false,
      },
      {
        productId: "c2ded8b3-f12b-49b5-8843-f5ba48ed504a",
        productName: "Fish cutlets",
        ingredients:
          "fish fillets, pork lard, white bread, water, onion, bread crumbs, eggs, salt, and ground black pepper.",
        productImg: "fish_cutlets",
        vega: false,
      },
      {
        productId: "9280fd3e-89c1-4eea-8d62-a233eeb6df91",
        productName: "Burger patty",
        ingredients:
          "lean ground beef, pork lard, mustard, salt, and ground black pepper.",
        productImg: "burger_patty",
        vega: false,
      },
      {
        productId: "4fe2eb5e-1ea6-4b1a-844b-93fb84472ebc",
        productName: "Turkey burger patty",
        ingredients: "turkey, pork lard, mustard, salt, ground black pepper.",
        productImg: "Turkey_burger",
        vega: false,
      },
      {
        productId: "1ca85597-2b16-4e0e-be12-4e22550162f2",
        productName: "Meatballs",
        ingredients:
          "lean ground pork, lean ground beef, rice, pork lard, onion, eggs, wheat flour, salt, and ground black pepper.",
        productImg: "DSC_7788",
        vega: false,
      },
      {
        productId: "68333802-e776-4cc8-9ca9-768d9b1a8e2a",
        productName: "Kid's quail egg meatballs",
        ingredients: "lean ground beef, onion, quail eggs, salt.",
        productImg: "Kids_meatballs",
        vega: false,
      },
      {
        productId: "b2a3b1a6-68e0-40b4-859b-e1f450aac44d",
        productName: "Meatballs «Turkey»",
        ingredients: "turkey, onion, salt.",
        productImg: "Turkey_meatballs",
        vega: false,
      },
      {
        productId: "92111f79-6387-46aa-9e13-bd637c451d53",
        productName: "Meat buckwheat cutlets",
        ingredients:
          "lean ground pork, pork lard, buckwheat, bread crumbs, eggs, onion, salt, and ground black pepper.",
        productImg: "buckwheat_cutlets",
        vega: false,
      },
    ],
  },
  {
    categoryName: "Cabbage rolls",
    categoryId: "3cc0575a-e1c8-413c-870d-cafb5893d107",
    products: [
      {
        productId: "b2d7e756-2ec7-47e7-b0f2-d28e466034e7",
        productName: "Pork cabbage rolls",
        ingredients:
          "green cabbage, rice, lean ground pork, fried onion, fried carrot, refined sunflower oil, salt, and ground black pepper. ",
        productImg: "pork_cabbage_rolls",
        vega: false,
      },
      {
        productId: "9b4be3b7-a5d3-4326-a442-995a25bdc5d3",
        productName: "Veal and beef cabbage rolls",
        ingredients:
          "green cabbage, rice, lean ground beef, lean ground veal, fried onion, fried carrot, refined sunflower oil, salt, and ground black pepper. ",
        productImg: "veal_and_beef_cabbage_rolls",
        vega: false,
      },
      {
        productId: "5f64ab59-cb7a-42d0-a0a0-8e96a1777817",
        productName: "Mushroom cabbage rolls",
        ingredients:
          "rice, green cabbage, mushroom, onion, carrot, refined sunflower oil, salt, and ground black pepper. ",
        productImg: "mushroom_cabbage_rolls",
        vega: true,
      },
      {
        productId: "8900b255-f6b2-490b-8854-edb2f19f73c8",
        productName: "Stuffed peppers ",
        ingredients:
          "bell pepper, rice, lean ground pork, onion, carrot, refined sunflower oil, salt, and ground black pepper. ",
        productImg: "stuffed_peppers",
        vega: false,
      },
      {
        productId: "7df02154-921b-4335-94f6-7aed99a0248b",
        productName: "Mushroom stuffed peppers",
        ingredients:
          "bell pepper, rice, mushroom, onion, carrot, refined sunflower oil, salt, and ground black pepper. ",
        productImg: "mushroom_stuffed_peppers",
        vega: false,
      },
    ],
  },
  {
    categoryName: "Corn dog, donuts, pancakes",
    categoryId: "67246e97-f888-4ff0-ad0d-a82c5064c7ee",
    products: [
      {
        productId: "6d5afc5a-bf4c-4b8c-80ca-110a21016fc5",
        productName: "Corn dog with condensed milk",
        ingredients:
          "condensed milk, eggs, wheat flour, yogurt, butter, white sugar, baking soda, baking powder, instant coffee, and salt.",
        productImg: "corndog_scummed_milk_h",
        vega: false,
      },
      {
        productId: "e8ccc693-f26d-45a2-b7fd-169f407d56b4",
        productName: "Corn dog with apples",
        ingredients:
          "apples, eggs, wheat flour, yogurt, butter, water, lemon, white sugar, mango butter, baking powder, baking soda, cinnamon, salt, gellan gum E418",
        productImg: "corndog_apple_h",
        vega: false,
      },
      {
        productId: "ec90fcb7-9f37-4d74-b00a-c62e71700252",
        productName: "Chocolate corn dog",
        ingredients:
          "eggs, wheat flour, yogurt, butter, dark chocolate, cacao, white sugar, baking powder, baking soda, and salt.",
        productImg: "DSC_3248",
        vega: false,
      },
      {
        productId: "c84c0c8f-3bdb-41bc-8e72-ac603cdc03b1",
        productName: "Corn dog with ham and cheese",
        ingredients:
          "eggs, wheat flour, ham, tomatoes, mozzarella, cream cheese Philadelphia, yogurt, butter, mustard seed, white sugar, baking powder, baking soda, salt.",
        productImg: "corndog_ham_cheese_h",
        vega: false,
      },
      {
        productId: "0431ccef-7554-49f8-bc77-3262be4600b4",
        productName: "Corn dog with sour cherry",
        ingredients:
          "frozen sour cherry, eggs, wheat flour, yogurt, butter, poppy seed, white sugar, lemon, baking powder, baking soda, salt, gellan gum E418",
        productImg: "corndog_cherry_h",
        vega: false,
      },
      {
        productId: "509239bf-21da-4053-9f98-03a4b25f181d",
        productName: "Donuts",
        ingredients:
          "wheat flour, eggs, Greek yogurt, butter, white sugar, vanilla sugar, baking powder, baking soda, salt, chocolate glaze, white chocolate glaze, strawberry glaze, decor: chocolate crunch, sublimation raspberry. and confectionery.  ",
        productImg: "DSC_5529",
        vega: false,
      },
      {
        productId: "96222314-1770-4c34-b03a-ae55d963bcac",
        productName: "Pancakes ",
        ingredients:
          "wheat flour, milk, eggs, refined sunflower oil, white sugar, vanilla sugar, baking powder, and salt.",
        productImg: "_MG_3534",
        vega: false,
      },
    ],
  },
  {
    categoryName: "Other",
    categoryId: "e470794f-d122-4b4c-bc36-90d57992b425",
    products: [
      {
        productId: "27e0b404-ec0a-4c41-a9b6-f6d4295f47b4",
        productName: "Stuffed Mackerel",
        ingredients:
          "Mackerel, carrot, onions, refined sunflower oil, spices, salt, and ground black pepper.  ",
        productImg: "stuffed_mackerel",
        vega: false,
      },
      {
        productId: "fa6cb2f2-33d1-4ceb-a4ab-b158238820a4",
        productName: "Chicken nuggets",
        ingredients:
          "chicken breast, corn flakes, milk, wheat flour, eggs, corn starch, pepper, salt, and ground black pepper. ",
        productImg: "DSC_6725",
        vega: false,
      },
      {
        productId: "680efd1c-0227-493f-976f-e87854cb1cb9",
        productName: "Fish nuggets",
        ingredients:
          "fish fillets, corn flakes, water, wheat flour, eggs, corn starch, fish spice, salt, ground black pepper. ",
        productImg: "DSC_6641",
        vega: false,
      },
      {
        productId: "308df3ed-a95a-4426-9334-c4b5a3e01ec6",
        productName: "Potato croquettes",
        ingredients:
          "potatoes, bread crumbs, wheat flour, eggs, butter, fresh dill, ground dried garlic, nutmeg muscade, bay leaves, salt, and ground black pepper. \n",
        productImg: "potato_croquettes",
        vega: false,
      },
      {
        productId: "944030f4-514b-4bab-986a-2745612b35b2",
        productName: "Cheese potato croquettes",
        ingredients:
          "potatoes, cheddar cheese, bread crumbs, wheat flour, eggs, ground dried garlic, salt, and ground black pepper. \n",
        productImg: "cheese_croquettes",
        vega: false,
      },
      {
        productId: "2661fd4b-146e-4377-8177-01fa583935a0",
        productName: "Cheese sticks",
        ingredients:
          "Cheddar cheese, bread crumbs, wheat flour, eggs, carry powder",
        productImg: "cheese_sticks",
        vega: false,
      },
      {
        productId: "3ff4aec2-1b1c-4253-aa4c-3a3571a4780f",
        productName: "Meatball soup",
        ingredients:
          "potatoes, pork meatballs (lean ground pork,  eggs, salt, ground black pepper), carrot, onion, fresh dill and parsley. ",
        productImg: "DSC_7954",
        vega: false,
      },
      {
        productId: "96bb27d4-fa10-41d8-a308-e42a1fd645ca",
        productName: "Red borscht",
        ingredients:
          "potatoes, beet, cabbage, pork meatballs (lean ground pork, eggs, salt, ground black pepper, red pepper), carrot, onion, tomato sauce, fresh dill and parsley, white sugar, vinegar, salt, ground black pepper.",
        productImg: "red_borscht2",
        vega: false,
      },
    ],
  },
];
