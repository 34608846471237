export const SiteCredits = () => {
  return (
    <div className="credits">
      © 2024 Multicook Brandon | By{" "}
      <a
        href="https://www.linkedin.com/in/oleksandr-kochenko/"
        target="_blank"
        rel="noreferrer"
      >
        Alex Kochenko
      </a>
    </div>
  );
};
