import { Icon } from "@iconify/react/dist/iconify.js";
import { contacts } from "../data/contacts";

export const Social = ({ styles }) => {
  return (
    <div className={styles}>
      <a href={contacts.fb} target="_blank" rel="noreferrer" className="link">
        <Icon icon="mage:facebook" className="fb" />
      </a>

      <a href={contacts.ig} target="_blank" rel="noreferrer" className="link">
        <Icon icon="mdi:instagram" className="ig" />
      </a>
      <a href={`mailto:${contacts.email}`} className="link">
        <Icon icon="logos:google-gmail" className="ml" />
      </a>
    </div>
  );
};
