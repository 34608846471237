import { contacts } from "../data/contacts";
import { Social } from "./Social";

export const Contacts = () => {
  return (
    <div className="contacts">
      <p className="address">
        <span>{contacts.address}</span>
        <span className="email-address">{contacts.email}</span>
      </p>
      <Social styles="social" />
    </div>
  );
};
