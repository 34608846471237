import { Icon } from "@iconify/react/dist/iconify.js";
import { contacts } from "../data/contacts";

export const Delivery = () => {
  return (
    <div>
      <h3 className="section-header delivery-header">
        Delivery available <span className="note">*</span>
      </h3>
      <a href={`tel:${contacts.phoneCall}`} className="phone-link">
        <Icon icon="mingcute:phone-fill" className="phone-icon" />
        <h3 className="section-header phone">{contacts.phone}</h3>
      </a>
      <div className="delivery-detiles">
        <div className="detiles-section">
          <p>Free delivery - orders from $50</p>
          <p>$4.99 - orders under $50 </p>
        </div>
        <div className="detiles-section">
          <p>Same day delivery - orders before 4 p.m.</p>
          <p>$15 - ASAP delivery up to 9 p.m. </p>
        </div>
      </div>
      <p className="detiles-section">
        <span className="asterix">*</span> - Up to 15 km from the shop
      </p>
    </div>
  );
};
