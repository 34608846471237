import logo from "../img/final3.png";

export const Hero = () => {
  return (
    <div className="hero-container">
      <img
        loading="lazy"
        src={logo}
        alt="Multicook Brandon"
        className="hero-logo"
      />
    </div>
  );
};
