export const Map = () => {
  const isMobile = window.innerWidth < 1020;
  return (
    <div className="map">
      <iframe
        className="map-embed"
        title="Multicook Brandon, 155 18th Street"
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2355.0059349743515!2d-99.96420332393937!3d49.847119830621786!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x52e7970053872af9%3A0xe79b5369501f13aa!2sMulticook%20Brandon!5e1!3m2!1sen!2sca!4v1729297780370!5m2!1sen!2sca"
        width={isMobile ? window.innerWidth : 400}
        height={300}
        style={{ border: 0 }}
        allowFullScreen
        loading="lazy"
        referrerPolicy="no-referrer-when-downgrade"
      ></iframe>
    </div>
  );
};
