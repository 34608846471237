export const viewportData = {
  desktop: {
    breakpoint: { max: 4000, min: 1280 },
    items: 3,
  },
  tablet: {
    breakpoint: { max: 1280, min: 880 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 880, min: 0 },
    items: 1,
  },
};
