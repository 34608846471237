import { contacts } from "../data/contacts";
import { Map } from "./Map";
import { Social } from "./Social";

export const Footer = () => {
  return (
    <div className="footer">
      <Map />
      <div className="footer-contacts">
        <h6 className="footer-header">Contact us:</h6>
        <p>
          <span className="footer-contacts-name">Address: </span>
          {contacts.address}
        </p>
        <p>
          <span className="footer-contacts-name">E-mail: </span>
          {contacts.email}
        </p>
        <p>
          <span className="footer-contacts-name">Phone: </span>
          {contacts.phone}
        </p>
        <Social styles="social-footer" />
      </div>
    </div>
  );
};
